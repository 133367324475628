
import { Component, Vue } from 'vue-property-decorator';

interface ISlide {
  imgSrc: string;
}

@Component({
  components: {
  }
})
export default class DemoDialogContent extends Vue {
  public slides: ISlide [] = [
    {
      imgSrc: '/tutorial/slide_case_key_metrics.png',
    },
    {
      imgSrc: '/tutorial/slide_case_summary.png',
    },
    {
      imgSrc: '/tutorial/slide_case_timeline.png',
    },
    {
      imgSrc: '/tutorial/slide_select_case.png',
    },
    {
      imgSrc: '/tutorial/slide_graph_data.png',
    },
    {
      imgSrc: '/tutorial/slide_graph_zoom.png',
    },
    {
      imgSrc: '/tutorial/slide_graph_settings.png',
    },
    {
      imgSrc: '/tutorial/slide_graph_tooltip.png',
    },
    {
      imgSrc: '/tutorial/slide_export.png',
    }
  ];
}

