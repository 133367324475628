
import { AllLabels } from '@/interfaces';
import { demoStore, labelsStore, termsAndConditionsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import ResultsPage from '@/pages/HomePage/ResultsPage/ResultsPage.vue';
import DemoDialog from './DemoDialog.vue';
import { demoService } from '@/services';

@Component({
  components: {
    DemoDialog,
    ResultsPage,
  }
})
export default class DemoPage extends Vue {

  public mounted(): void {
    demoService.showDemo();
  }

  public beforeDestroy(): void {
    demoService.hideDemo();
  }

  public get showDemoDialog(): boolean {
    return demoStore.showDemoDialog && termsAndConditionsStore.acceptedTermsAndConditions;
  }

  public get ready(): boolean {
    return !!uploadStore.selectedCase;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public closeDemoDialog()
  {
    demoStore.setShowDemoDialog(false);
  }
}

