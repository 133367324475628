
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AppButton from '@/components/ui/AppButton.vue';
import DemoDialogContent from './DemoDialogContent.vue';

@Component({
  components: {
    AppButton,
    DemoDialogContent
  }
})
export default class DemoDialog extends Vue {

    @Prop({ required: true })
    public dialog!: boolean;

    @Emit()
    public close(): void {
    }

    public get maxWidth(): string {
      // max size is dependent on screenshot content
      return '1024px';
    }
}

